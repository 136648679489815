/* Desktop */

body {
  min-height: 100vh;
}
.tds-landing-container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
    "header main"
    "footer main";
}

.tds-landing-sub-page-container {
  min-height: 100vh;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";
}
.tds-landing-cta-section {
  padding-left: 50px;
  padding-right: 50px;
}
.tds-landing-container main {
  height: 100vh;
  background-image: url("../assets/images/td-buildings.jpg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  grid-area: main;
}
.tds-landing-main-message {
  background-color: green;
  padding: 57px 110px 56px 109px;
  width: 42%;
  margin-top: 144px;
  font-size: 20px;
  color: #ffffff;
}
.tds-landing-request-access-confirmation,
.tds-landing-request-access {
  z-index: 1;
  grid-area: main;
}
.hide-overlay {
  display: none;
}

@media only screen and (max-width: 375px) {
}

@media only screen and (max-width: 1300px) {
  .tds-landing-sub-page-container .tds-container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-landing-container main {
    background-position: 20% 0%;
  }
  .tds-landing-main-message {
    width: 100%;
    padding: 57px 133px 56px 50px;
  }
}

/* Tablet */

@media only screen and (max-width: 1023px) {
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .tds-landing-container {
    grid-template-columns: 320px 1fr;
  }
  .tds-landing-container main {
    background-position: 20% 0%;
  }
  .tds-landing-main-message {
    width: 100%;
    padding: 57px 30px 56px 30px;
  }
}

/* MOBILE */

@media only screen and (max-width: 767px) {
  .tds-landing-container {
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      "header"
      "footer";
  }
  .tds-landing-container main {
    display: none;
  }
  .tds-landing-request-access,
  .tds-landing-request-access-confirmation {
    grid-column: 1;
    grid-row: 1 / 3;
  }
}

@media only screen and (max-width: 375px) {
}
