html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.tds-container {
  width: 1300px;
  margin: auto;
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-container {
    width: 100%;
    margin: auto;
  }
}

/* Tablet */

@media only screen and (max-width: 1023px) {
  .tds-container {
    width: 100%;
    margin: auto;
  }

  body.no-scroll {
    overflow: hidden;
  }
}
