.tds-logo {
  float: left;
  padding: 10px 0px;
}

.tds-logo img {
  height: 30px;
  min-width: 150px;
}

.tds-header {
  height: 50px;
}

.tds-section-left {
  width: 50%;
  height: 50px;
  float: left;
}

.tds-top-nav.unauthenticated {
  display: none;
}

.tds-top-nav {
  list-style: none;
  padding: 14px;
}

.tds-top-nav .td-icon-homepage {
  padding-right: 5px;
  font-size: 15px;
}

.tds-top-nav.right {
  padding-right: 0px;
  padding-top: 10px;
  display: inline-block;
}

.tds-top-nav.right:last-child {
  padding-left: 0px;
}

.tds-top-nav a {
  font-size: 12px;
  padding: 19px 5px 25px 15px;
  color: #038203;
}

.tds-mobile-nav-right .tds-mobile-nav-username {
  float: left;
  padding: 14px 10px;
  font-size: 16px;
  color: #038203;
}

.header-utility-bar {
  height: 36px;
  width: 100%;
  z-index: 8000;
  background-color: #008a00;
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-navbar li a {
    font-size: 13px;
  }
  
  .tds-navbar {
    padding: 22px 0px 22px 10px;
  }

  .tds-navbar a.tds-navbar-dropdown-link {
    padding: 27px 5px 27px 5px;
  }

  .tds-navbar a.tds-navbar-dropdown-link.active {
    padding: 27px 5px 27px 5px;
  }

  .tds-search-input-container {
    padding: 10px 5px 10px 10px;
  }

  .tds-logo {
    padding: 10px 5px;
    margin-left: 10px;
  }

  .tds-dropdown-content.active {
    font-size: 13px;
  }
}

/* Tablet */

@media only screen and (max-width: 1023px) {
  .tds-header {
    display: none;
  }

  .tds-nav {
    display: none;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
}

/* MOBILE */

@media only screen and (max-width: 767px) {
}

@media only screen and (max-width: 375px) {
}
