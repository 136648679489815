.tooltip-descriptor-text {
  font-size: 15px;
  cursor: pointer;
  font-family: "TD Graphik Medium";
  color: #038203;
  margin-top: 10px;
  float: right;
}

.tooltip-descriptor-left-padding-for-fr {
  margin-top: -22.5px;
}

.tooltip-msg-box {
  width: 320px !important;
  padding-left: 20px !important;
  margin-top: 5px;
  margin-left: 5px;
  border: solid 0.1px grey;
  z-index: 1;
}

.tooltip-close {
  float: right;
  font-size: 20px;
  color: black;
  cursor: pointer;
  margin-top: -6px;
  margin-right: -10px;
  line-height: -1;
}

.tooltip-arrow {
  border-top: solid 0.1px grey;
  border-left: solid 0.1px grey;
}

.descriptor-text {
  padding-left: 22px;
  font-weight: bold;
}


@media only screen and (max-width: 1300px) and (min-width: 896px) {
  .tooltip-descriptor-left-padding-for-fr {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 740px) and (min-width: 500px) {
  .tooltip-descriptor-left-padding-for-fr {
    margin-top: 10px;
  }
}



