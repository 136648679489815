/* Desktop */

.tds-tab-menu-container {
  margin: 20px 0px 10px 0px;
  width : 100%;
}

.tds-tab-container {
  width: 100%;
}

.tds-tab-container.home-page {
  min-height: 1000px;
}

.tds-tab-menu-container .td-multi-single-select-buttons {
  margin-top  : 0px;
  width       : 75%;
  margin-left : auto;
  margin-right: auto;
}

.tds-tab-menu-container .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper {
  min-width: 50%;
  width    : 50%;
  height   : auto;
}

.tds-tab-menu-container .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper label {
  padding    : 4px 0px;
  font-size  : 13px;
  line-height: 1.25;
}

.tds-tab-menu-container .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper:first-child label {
  border-top-left-radius   : 5px;
  border-bottom-left-radius: 5px;
}

.tds-tab-menu-container .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper:last-child label {
  border-top-right-radius   : 5px;
  border-bottom-right-radius: 5px;
}

.tds-most-popular-select {
  width: 30%;
}

.tds-most-popular-select .form-control {
  height   : 30px;
  font-size: 13px;
}

.tds-most-popular-select.form-group.td-form-group-padding {
  padding: 0px;
}

.tds-tab-most-popular-article-list {
  display   : block;
  padding   : 0px;
  margin    : 0px;
  list-style: none;
  min-height: 950px;
}

.tds-tab-most-popular-article-list li {
  display: flex;
  margin : 20px 0px;
}

.tds-tab-most-popular-article-list-item-img-container {
  min-width      : 200px;
  min-height     : 124px;
  width          : 200px;
  height         : 124px;
  background-size: cover;
  object-fit     : cover;
}

.tds-tab-most-popular-article-list-item-content {
  display: block;
  padding: 0px 20px 0px 50px;
  width: 100%;
}

.tds-tab-most-popular-article-list li h3 {
  display: block;
}

.tds-tab-most-popular-article-list-item-content-date-byline {
  display      : block;
  font-size    : 13px;
  margin-bottom: 5px;
  width: 100%;
  display: flex;
}

.tds-tab-most-popular-article-list-item-content-date {
  width: 25%;
  text-align: left;
}
.tds-tab-most-popular-article-list-item-content-byline {
  width: 75%;
  text-align: left;
}

.tds-tab-most-popular-article-list-item-content-description {
  font-size    : 13px;
  margin-bottom: 5px;
  overflow     : hidden;
  float        : left;
}

.tds-tab-most-popular-article-list-item-content-read-full-article {
  font-size: 13px;
  clear    : both;
  display  : block;
}

.tds-tab-most-recent,
.tds-tab-todays-publications {
  display: none;
}

.tds-tab-most-popular-article-list-item-content-equities-info {
  font-size: 13px;
}
.tds-tab-most-popular-article-list-item-content-equities-info dl {
  margin: 0px;
  padding: 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.tds-tab-most-popular-article-list-item-content-equities-info dd {
  width: 75%;
  height: auto;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
}

.tds-tab-most-popular-article-list-item-content-equities-info dt {
  width: 25%;
  height: auto;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
}

.tds-tab-container .tds-search-results-column.title {
  width: 31%;
}

.tds-tab-container .tds-search-results-column.action {
  width: 16%;
}

.tds-tab-menu-container.two-tabs .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper {
  width: 50%;
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-tab-container.home-page {
    min-height: 1300px;
  }
  .tds-tab-most-popular-article-list {
    min-height: 975px;
  }
  .tds-tab-container .tds-search-results-column.title {
    width: 30%;
  }

  .tds-tab-container .tds-search-results-column.action {
    width: 17%;
  }


}

/* Tablet */

@media only screen and (max-width: 1023px) {
  .tds-tab-container.home-page {
    min-height: 1300px;
  }
  .tds-tab-most-popular-article-list {
    min-height: 975px;
  }
  .tds-tab-container .tds-search-results-column.title {
    width: 60%;
  }

  .tds-tab-container .tds-search-results-column.action {
    width: 17%;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .tds-tab-menu-container .td-multi-single-select-buttons {
    width: 95%;
  }
  .tds-tab-most-popular-article-list-item-content-equities-info dd {
    width: 65%;
  }

  .tds-tab-most-popular-article-list-item-content-equities-info dt {
    width: 35%;
  }
}

/* MOBILE */

@media only screen and (max-width: 767px) {
  .tds-tab-menu-container .td-multi-single-select-buttons {
    width: 100%;
  }

  .tds-tab-menu-container .td-multi-single-select-buttons {
    width: 100%;
  }

  .tds-tab-menu-container .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper {
    width: 100%
  }

  .tds-tab-menu-container .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper:first-child label {
    border-top-left-radius   : 5px;
    border-top-right-radius  : 5px;
    border-bottom-left-radius: 0px;
    border-right             : 2px solid #038203;
    border-bottom-width      : 1px;
  }

  .tds-tab-menu-container .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper:nth-child(2) label {
    border-right       : 2px solid #038203;
    border-top-width   : 1px;
    border-bottom-width: 1px;
  }

  .tds-tab-menu-container .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper:nth-child(3) label {
    border-top-width         : 1px;
    border-bottom-left-radius: 5px;
    border-top-right-radius  : 0px;
  }

  .tds-tab-menu-container .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper label {
    padding    : 10px 0px;
    font-size  : 18px;
    line-height: 1.25;
  }

  .tds-tab-most-popular-article-list-item-img-container {
    display: none;
  }

  .tds-tab-most-popular-article-list-item-content {
    padding: 0px 0px 10px 0px;
    height : auto;
  }

  .tds-tab-most-popular-article-list li {
    margin: 10px 0px;
  ;
    border-bottom: 1px solid #CCC;
  }

  .tds-tab-most-popular-article-list li:last-child {
    border-bottom: 0px;
  }

  .tds-tab-menu-container {
    margin: 20px 0px;
  }

  .tds-tab-container .tds-search-results-column.title {
    width: 60%;
  }

  .tds-tab-container .tds-search-results-column.action {
    width: 100%;
  }


  .tds-tab-menu-container.two-tabs .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper {
    width: 100%;
  }

  .tds-tab-menu-container.two-tabs .td-multi-single-select-buttons.td-multi-single-select-buttons-segmented .td-multi-single-select-button-wrapper:nth-child(2) label {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 5px;
  }


}

@media only screen and (max-width: 375px) {
  .tds-tab-container .tds-search-results-column.action {
    width: 100%;
  }
}

/* TD EMERALD Standards Overrides BEGIN */

/* TD EMERALD Standards Overrides BEGIN */