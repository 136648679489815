/* 
    DO NOT MODIFY DIRECTLY IN PROJECT gsbm-continuous-testing

    ALWAYS COPY FROM tds-one-portal-static master branch

*/

/* Desktop */

/* The Modal (background) */
.tds-modal {
    display         : none;
    /* Hidden by default */
    position        : fixed;
    /* Stay in place */
    z-index         : 1;
    /* Sit on top */
    left            : 0;
    top             : 0;
    width           : 100%;
    /* Full width */
    height          : 100%;
    /* Full height */
    overflow        : auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

.tds-modal.active {
    display: block;
}

/* Modal Content/Box */
.tds-modal-content {
    background-color: white;
    margin          : 5% auto;
    /* 15% from the top and centered */
    padding         : 20px;
    border          : 1px solid #888;
    width           : 75%;
    /* Could be more or less, depending on screen size */
}

.tds-modal-content.tds-contact-us {
    width  : 90%;
    padding: 50px 40px 40px 40px;
}

/* The Close Button */
.tds-modal-close {
    float       : right;
    font-size   : 30px;
    color       : black;
    cursor      : pointer;
    margin-top  : -12px;
    margin-right: -10px;
    line-height : 1;
}

.modal-action-button-container{
    display: flex;
    justify-content: center;
    margin-top: 1em
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
    .tds-modal-content {
        width: 98%;
    }

}

/* Tablet */

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .tds-modal-content {
        margin: 25% auto;
    }

}

/* MOBILE */

@media only screen and (max-width: 767px) {
    .tds-modal-content {
        margin: 25% auto;
    }
}

/* TD EMERALD Standards Overrides BEGIN */



/* TD EMERALD Standards Overrides BEGIN */