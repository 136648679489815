/* Desktop */
  .tds-sn-faq-container {
    margin-bottom: 10px;
    width        : 100%;
    margin-top   : 10px;
  
    ul.dashed {
      list-style: none;
    }
  
    ul.dashed>li {
      text-indent: -17px;
    }
  
    ul.dashed>li:before {
      content      : "–";
      padding-right: 11px;
    }
  
  
    .tds-sn-faqs-pdf-container {
      display       : flex;
      flex-direction: row;
      margin-top    : 20.2px;
  
      .product-category {
  
        &:nth-child(n+2) {
          margin-top: 20px;
        }
  
        .product-category-list {
          margin-top: 10px;
    
          a {
            font-weight: bold;
          }
    
          .product-card-icon {
            padding-left: 5px;
          }
        }
      }      
    }
  }

  .tds-sn-faq-answers-container {
    h2 {
      color: green;
    }
    .answers-list {
      padding-left: 50px;
      li {
        color: black;
      }
    }
  }
  .tds-faq-div {
    &.margin-top-en {
      margin-top: 60px;
    }
    &.margin-top-fr {
      margin-top: 80px;
    }
  }
  .hr {
    border: 1px solid #ccc;
  }
  /* Tablet */
  
  /* MOBILE */
  
  /* TD EMERALD Standards Overrides BEGIN */
  
  /* TD EMERALD Standards Overrides BEGIN */