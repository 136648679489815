.request-app-access-header {
  margin-top: 10px;
}

.request-app-access-footer {
  margin-top: 20px;
}

.request-app-access-fields {
  margin-top: 10px;
}