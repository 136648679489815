.tds-mobile-navbar .tds-mobile-navbar-item {
  list-style: none;
  font-size: 16px;
  font-family: "TD Graphik Medium";
}

.tds-mobile-navbar .tds-mobile-navbar-item a {
  color: #1c1c1c;
}

.tds-mobile-nav-right .tds-mobile-navbar .tds-mobile-navbar-item:first-child {
  border-top: none;
}

.tds-mobile-navbar-dropdown-link .td-icon-downCaret,
.tds-mobile-navbar-dropdown-link .td-icon-upCaret {
  float: right;
  padding-right: 20px;
  color: #777;
  font-size: 22px;
}

.tds-mobile-navbar-dropdown-link .td-icon-upCaret {
  display: none;
}

.tds-mobile-navbar-dropdown-link.active .td-icon-downCaret {
  display: none;
}

.tds-mobile-navbar-dropdown-link .td-icon-upCaret {
  display: block;
}

.tds-mobile-dropdown-content {
  clear: both;
  margin-left: 18px;
  margin-bottom: 0px;
  padding-left: 0px;
  display: none;
}

.tds-mobile-dropdown-content.active {
  display: block;
}

.tds-mobile-dropdown-content li {
  list-style: none;
  padding: 10px 0px;
  border-bottom: 1px solid #dadada;
}
