/* Desktop */

.tds-sn-collapse-container {
    width: 100%;
  
    &.open, &:only-child, &:last-child {
      .summary {
        border-bottom: 1px solid #CCCCCC;
      }
    }
  
    .summary {
      border-top: 1px solid #CCCCCC;
      padding: 12px 10px;
  
      button {
        border: 0;
        padding: 0;
        background-color: inherit;
        outline: none;
  
        &:focus-visible {
          outline: auto;
        }
      }
  
      .td-icon-collapse, .td-icon-expand {
        display: inline-block;
        font-size: 18px;
        color: #008A00;
        vertical-align: middle;
        margin-right: 15px;
        margin-bottom: 5px;
        &.parent-sect-margin-top {
          margin-top: 6px;
        }
      }
      
      h2 {
        display: inline-block;
        font-size: 15px;
        text-align: left;
        margin-bottom: 0;
      }
    }
  }
  
  /* Tablet */
  
  /* MOBILE */
  
  @media only screen and (max-width: 767px) {
    .tds-sn-collapse-container {  
      .summary {
        h2 {
          font-size: 18px;
        }
      }
    }
  }
  
  @media only screen and (max-width: 375px) {
    .tds-sn-collapse-container {  
      .summary {
        h2 {
          font-size: 16px;
        }
      }
    }
  }
  
  /* TD EMERALD Standards Overrides BEGIN */
  
  /* TD EMERALD Standards Overrides BEGIN */
  