.tds-top-nav .tds-nav-item {
  display: inline;
  padding: 10px 0px;
  position: relative;
  cursor: pointer;
}

.tds-top-nav .tds-nav-item a {
  font-size: 12px;
  padding: 19px 5px 25px 15px;
  color: #038203;
}

.tds-top-nav .tds-nav-item a:hover {
  color: #038203;
}

.tds-top-nav .tds-nav-item a .td-icon {
  padding-left: 5px;
}

.tds-top-nav .tds-nav-item .tds-dropdown-content a,
.tds-navbar .tds-nav-item .tds-dropdown-content a {
  padding: 0px 0px 0px 0px;
  color: #1c1c1c;
  text-decoration: none;
}

.tds-top-nav .tds-nav-item .tds-dropdown-content a:hover,
.tds-navbar .tds-nav-item .tds-dropdown-content a:hover {
  color: #1c1c1c;
}

.tds-top-nav .tds-nav-item.request-a-meeting span.td-icon-bookAppointment {
  position: relative;
  top: 3px;
  right: 5px;
}

.tds-top-nav .tds-top-nav-dropdown-link {
  padding: 19px 5px 19px 15px;
}

.tds-top-nav .tds-top-nav-dropdown-link:hover {
  text-decoration: none;
}

.tds-top-nav .tds-top-nav-dropdown-link.active {
  text-decoration: none;
  background-color: white;
  padding: 20px 5px 17px 15px;
  border-bottom: 3px solid white;
  -webkit-box-shadow: 0px 0px 0px #eee, -2px 2px 2px #eee, 2px 2px 2px #eee;
  box-shadow: 0px 0px 0px #eee, -2px 2px 2px #eee, 2px 2px 2px #eee;
}

.tds-dropdown-content {
  display: none;
}

.tds-dropdown-content.active {
  display: block;
  position: absolute;
  background: white;
  z-index: 101;
  padding: 0px;
  font-family: "TD Graphik Medium";
  font-size: 16px;
  min-width: 250px;
  margin-top: 7px;
  left: 0px;
  -webkit-box-shadow: 0px 0px 0px #eee, -2px 2px 2px #eee, 2px 2px 2px #eee;
  box-shadow: 0px 0px 0px #eee, -2px 2px 2px #eee, 2px 2px 2px #eee;
}

.tds-dropdown-content.active.right {
  right: 0;
  left: auto;
}

.tds-navbar .tds-dropdown-content.active {
  margin-top: 24px;
}

.tds-top-nav .tds-dropdown-content.active {
  margin-top: 12px;
}

// .tds-dropdown-content.active.right.language,
// .tds-dropdown-content.active.right.user {
//   width: 250px;
// }

// .tds-dropdown-content.active.right.language .td-icon-checkMark {
//   float: right;
// }

.tds-dropdown-content.active.language,
.tds-dropdown-content.active.right.user {
  width: 250px;
}

.tds-dropdown-content.active.language .td-icon-checkMark {
  float: right;
}

.tds-dropdown-content li,
.tds-navbar .tds-dropdown-content li {
  display: block;
  padding: 10px 10px 10px 20px;
  cursor: pointer;
}

.tds-dropdown-content li:hover {
  background-color: #f3f3f3;
}

.tds-dropdown-content .menu-header {
  padding: 10px 0 10px 20px;
  cursor: default;
}

.tds-dropdown-content .sub-menu li {
  display: block;
  padding: 10px 10px 10px 30px;
}

.tds-dropdown-content .sub-menu li a {
  font-family: "TD Graphik SemiLight";
}

.tds-section-right {
  float: right;
  height: 50px;
  min-width: 200px;
}

.tds-section-right1 {
  float: right;
  height: 50px;
}

.tds-login-button-ctn {
  padding: 4px;
}

.tds-nav-container {
  float: left;
}

.tds-nav {
  height: 70px;
  clear: both;
  box-shadow: 0 4px 2px -2px #ccc;
}

.tds-navbar {
  list-style: none;
  padding: 22px 22px 22px 0px;
  margin: 0px;
}

.tds-navbar li {
  display: inline;
  padding: 10px 0px;
  position: relative;
}

.tds-navbar li a {
  font-size: 16px;
  padding: 0px 15px;
  font-family: "TD Graphik Medium";
  color: #038203;
}

.tds-navbar li a .td-icon {
  padding-left: 5px;
}

.tds-navbar li.tds-logo {
  width: 200px;
  padding: 100px;
}

.tds-navbar .tds-nav-item {
  display: inline;
  padding: 20px 0px 30px 0px;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
}

.tds-navbar .tds-nav-item a {
  font-size: 16px;
  padding: 0px 15px;
  font-family: "TD Graphik Medium";
  color: #038203;
}

.tds-navbar .tds-nav-item a:hover {
  color: #038203;
}

.tds-navbar .tds-nav-item a .td-icon {
  padding-left: 5px;
}

.tds-navbar .tds-nav-item .tds-logo {
  width: 200px;
  padding: 100px;
}

.tds-navbar a.tds-navbar-dropdown-link {
  padding: 27px 5px 27px 15px;
}

.tds-navbar a.tds-navbar-dropdown-link:hover {
  text-decoration: none;
}

.tds-navbar a.tds-navbar-dropdown-link.active {
  color: #1c1c1c;
  text-decoration: none;
  background-color: white;
  padding: 27px 5px 27px 15px;
  border-bottom: 3px solid white;
  -webkit-box-shadow: 0px 0px 0px #eee, -2px 2px 2px #eee, 2px 2px 2px #eee;
  box-shadow: 0px 0px 0px #eee, -2px 2px 2px #eee, 2px 2px 2px #eee;
}

.tds-nav .tds-search-container {
  float: right;
  color: #038203;
  padding: 23px 0px 5px 0px;
  cursor: pointer;
}

.tds-search-bar-container {
  background-color: #f3f3f3;
}

.tds-search-input-container {
  padding: 10px 5px 10px 23px;
  height: 63px;
}

.tds-search-input-container .search {
  width: 85%;
  display: inline;
  border: none;
  float: left;
}

.tds-search-input-container .td-icon-search {
  color: white;
  font-size: 24px;
  background-color: #008a00;
  padding: 9px 40px 9px 40px;
  margin: 0px 0px 0px 0px;
  float: left;
  cursor: pointer;
}

.tds-search-input-container .td-icon-close {
  font-size: 24px;
  padding: 9px 0px 9px 10px;
  margin: 0px 0px 0px 0px;
  float: right;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-navbar .tds-nav-item a {
    font-size: 13px;
  }
}
