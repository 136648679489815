/* Desktop */
.tds-contact-us-container {
  display: flex;
  margin-top: 3px;
  flex-wrap: wrap;
}

.tds-contact-us-video-bar {
  width: 100%;
  height: 310px;
  background-size: cover;
  background-repeat: no-repeat;
}

.contact-us-learn-more-btn {
  margin-top: 10px;
}

.tds-contact-us-video-content {
  width: 50%;
  margin-right: 0;
  margin-left: auto;
  background-image: linear-gradient(
    to right,
    rgba(243, 243, 248, 0),
    #f3f3f8 30%
  );
  padding-left: 7%;
  height: 100%;

  display: flex;
  align-items: center;

  h1 {
    margin-bottom: 0px;
    font-size: 1.7rem;
  }

  section {
    max-width: 300px;
  }
  button {
    max-width: 230px;
  }
}

.contact-us-banner-container {
  background-color: #f6faf0;
  font-family: "TD Graphik SemiLight";
  font-weight: bold;
  padding-top: 0.7%;
  padding-bottom: 0.5em;
  height: max-content;
  margin: auto;
  width: 80%;

  &.margins {
    margin-bottom: 10px;
  }
}

.contact-us-banner-container-bg-color {
  background-color: white;
}

.contact-us-banner-title {
  margin-top: 10px;
  font-size: 1.2em;
  color: #038203;
  text-align: center;

  &.subtitle {
    font-size: medium;
    color: black;
  }
}

.contact-us-dropdown {
  width: 400px;
  font-weight: bold;
  margin: auto;
}

.vl {
  border-left: 1px solid grey;
  height: 350px;
}

.contact-us-help-detail-container {
  margin-left: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;

  &.one-column {
    grid-template-columns: 1fr;
  }

  .help-detail-img {
    margin-top: -20px;
    display: grid;
    place-items: center;
  }

  .help-detail-div {
    color: black;
    text-align: center;
    margin: auto;
    margin-top: -30px;
  }
}

@media only screen and (max-width: 767px) {
  .tds-contact-us-video-content {
    section {
      margin-left: -90px;
    }
  }

  .contact-us-dropdown {
    width: 250px;
    font-weight: bold;
    margin: auto;
  }

  .contact-us-help-detail-container {
    margin-left: 0px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .contact-us-learn-more-btn {
    margin-top: 10px;
    width: 150px;
  }

  .vl {
    margin-top: 30px;
    border-top: 1.1px solid grey;
    border-left: 0px;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 768px) {
  .tds-contact-us-container {
    width: 100%;
  }
}
