.tds-mobile-navbar-item.tds-mobile-navbar-main-link {
  clear: both;
  padding: 10px 0px;
  border-bottom: 1px solid #dadada;
}

.tds-mobile-navbar-dropdown-link {
  float: left;
  padding: 10px 0px;
  width: 100%;
  clear: both;
  border-bottom: 1px solid #dadada;
}
