@function unicode($str) {
  @return unquote('"') + unquote(str-insert($str, "\\", 1)) + unquote('"');
}

.learn-more {
  .tds-container {
    padding: 20px 20px 0 20px;
  }

  .back-button {
    display: inline-block;
    font-family: "TD Graphik Medium", sans-serif;
    font-size: 15px;
    color: #038203;
    font-weight: bold;
    margin-top: 20px;
    border: none;
    background: none;

    &:hover {
      text-decoration: none;
    }
  }

  .video-bar {
    width: 100%;
    height: 310px;

    background-size: cover;
    background-repeat: no-repeat;
  }

  .video-content {
    width: 50%;
    margin-right: 0;
    margin-left: auto;
    background-image: linear-gradient(
      to right,
      rgba(243, 243, 248, 0),
      #f3f3f8 30%
    );
    padding-left: 7%;
    height: 100%;

    display: flex;
    align-items: center;

    h1 {
      margin-bottom: 0px;
      font-size: 1.7rem;
    }

    section {
      max-width: 500px;
    }
  }

  .scrollable {
    height: 300px;
    overflow: auto;
  }

  .points {
    padding-bottom: 20px;
  }

  .points ul {
    margin-left: 30px;
    list-style: none;
    padding-left: 20px;
    text-align: left;
  }

  .points ul > li::before {
    color: #038203;
    content: unicode("2022");
    font-size: 1.625rem;
    line-height: 22px;
    margin-top: -1.9em;
    margin-left: -36px;
    padding-right: 1.7rem;
    vertical-align: middle;
  }
}

.learn-more-banner-title {
  min-width: 310px;
}

.learn-more-registration-button {
  float: left;
}

.learn-more-margin-bottom {
  margin-bottom: 7px;
}
/* Tablet */

@media only screen and (max-width: 1023px) {
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .learn-more .video-content {
    padding-left: 4%;
  }
}

/* MOBILE */
@media only screen and (max-width: 767px) {
  .tds-container.learn-more {
    .video-content h1 {
      padding-right: 15px;

      h1 {
        font-size: 1.1rem;
      }
    }
  }
  .tds-homepage-container .video-content {
    width: 85%;
    padding-left: 0%;
  }
}
