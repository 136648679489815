.custom-checkbox-filter-text {
  margin-top: 5px !important;
}

.custom-checkbox-filter-label {
  padding-left: 5px;
}


.custom-checkbox-filter-asterisk > label::before {
  content: "*";
  color: red;
  margin-right: 3px;
}