/* Desktop */

.tds-landing-request-access-container {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 71px 102px 71px 104px;
  position: relative;
}

.tds-landing-request-access-form-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 25px;
}

.tds-landing-request-access-form-grid-container-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 30px;
  align-items: end;
}

.request-access-relationship-margin-top {
  margin-top: 23px;
}

.tds-td-employee-form-container {
  margin: 20px 0 66px 0;
}

.registration {
  padding: 20px 20px 0px 20px;
}

.tds-landing-form-large-field {
  grid-column: auto / span 4;
  grid-row: auto;
}

.tds-landing-form-medium-field {
  grid-column: auto / span 2;
  grid-row: auto;
}

.tds-landing-form-small-field {
  grid-column: auto;
  grid-row: auto;
}

.tds-td-employee-form-dropdown {
  width: 304px;
  font-weight: bold;
  &.mi-status {
    width: auto;
  }
}

.tds-td-employee-form-four-column {
  width: 310px;
}

.tds-landing-form-close {
  position: absolute;
  right: 100px;
  top: 50px;
  font-size: 25px;
  color: #3c8725;
  cursor: pointer;
  line-height: 1;
}

.tds-landing-request-access-container h2 {
  margin-bottom: 30px;
}

.tds-landing-request-access-container p {
  margin-bottom: 40px;
}

.tds-landing-request-access-form-container {
  width: 100%;
}

.tds-landing-form-buttons button {
  display: inline-block;
  width: 170px;
  height: 42px;
  margin-right: 30px;
  margin-top: 15px;
}

.tds-landing-form-group > legend > h6,
.tds-landing-form-group fieldset label,
.tds-landing-form-group > label {
  font-weight: bold;
}

.tds-landing-form-group.form-inline label {
  margin-right: 20px;
}

.tds-business-line-form-group {
  margin-top: 15px;
}

.tds-business-line-form-group input {
  margin-top: 24px;
}

.tds-landing-form-required > label::before {
  content: "*";
  color: red;
  margin-right: 3px;
}

/* left align the check box */
.tds-landing-form-check-group > div {
  margin-left: -10px;
}

.form-control:disabled {
  background-color: hsl(0, 0%, 95%);
  border-color: #8c8c8c;
}

.tds-landing-request-access-confirmation-container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
}

.tds-landing-request-access-confirmation-container > h2 {
  margin-top: 143px;
  font-size: 22px;
}

.tds-landing-request-access-confirmation-container > p {
  margin-top: 58px;
  max-width: 703px;
  color: #707070;
  font-size: 20px;
  text-align: center;
}

.tds-landing-request-access-confirmation-container button {
  width: 170px;
  height: 42px;
  margin-top: 26px;
}

.tds-landing-confirmation-close {
  align-self: flex-end;
  font-size: 25px;
  color: #3c8725;
  cursor: pointer;
  line-height: 1;
}

.tds-landing-request-access .tds-landing-logo,
.tds-landing-request-access-confirmation .tds-landing-logo {
  margin-bottom: 50px;
  display: none;
}

.tds-landing-logo img {
  height: 50px;
  max-width: 230px;
}

.tds-landing-request-access-form-error {
  position: relative;
}

#company-address-line-1,
#company-address-line-2 {
  display: none;
}

.tds-landing-request-access-form-notes ul {
  list-style-position: outside;
  margin-left: -25px;
  font-size: 11px;
}

.tds-landing-request-access-form-notes font {
  font-size: 11px;
}

.tds-clear-indicator {
  padding: 0px 8px;
  font-size: 12px;
}

.registration-error-modal {
  width: 50%;
}

.registration-disclaimer {
  margin-bottom: 35px;
  font-weight: bold;
}

.registration-disclaimer-padding {
  margin-left: 3px;
}

@media only screen and (max-width: 1300px) {
  #company-address {
    display: none;
  }
  #company-address-line-1,
  #company-address-line-2 {
    display: block;
  }
  .tds-td-employee-form-four-column {
    width: auto;
  }
  .tds-td-employee-form-dropdown {
    width: auto;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-landing-request-access-container {
    padding: 63px 50px 63px 50px;
  }

  .tds-landing-request-access-form-grid-container,
  .tds-landing-request-access-form-grid-container-1 {
    grid-template-columns: 1fr 1fr;
  }

  .tds-landing-form-large-field {
    grid-column: auto / span 2;
  }

  .tds-landing-request-access-confirmation-container {
    padding: 50px 30px;
  }

  .tds-landing-form-close {
    right: 30px;
    top: 30px;
  }

  .tds-td-employee-form-four-column {
    width: auto;
  }

  .tds-td-employee-form-dropdown {
    width: auto;
    font-weight: bold;
  }
}

/* Tablet */

@media only screen and (max-width: 1023px) {
  .tds-landing-form-close {
    right: 30px;
    top: 30px;
  }
  .tds-td-employee-form-four-column {
    width: auto;
  }

  .tds-td-employee-form-dropdown {
    width: auto;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .tds-landing-request-access-container {
    padding: 58px 30px 24px 30px;
  }

  .tds-landing-request-access-form-grid-container,
  .tds-landing-request-access-form-grid-container-1 {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 30px;
  }

  .tds-landing-form-large-field {
    grid-column: auto / span 2;
  }

  .tds-landing-request-access-confirmation-container {
    padding: 50px 30px;
  }
  .tds-td-employee-form-four-column {
    width: auto;
  }

  .tds-td-employee-form-dropdown {
    width: auto;
    font-weight: bold;
  }
}

/* MOBILE */

@media only screen and (max-width: 767px) {
  .tds-landing-request-access-container {
    padding: 30px;
  }

  .tds-landing-request-access-form-grid-container,
  .tds-landing-request-access-form-grid-container-1 {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }

  .tds-landing-form-large-field {
    grid-column: auto;
  }

  .tds-landing-form-medium-field {
    grid-column: auto;
  }

  .tds-landing-form-buttons button {
    width: 100%;
    margin-bottom: 15px;
  }

  .tds-landing-request-access-confirmation-container {
    padding: 30px;
  }

  .tds-landing-request-access-confirmation-container > h2 {
    margin-top: 126px;
  }

  .tds-landing-request-access-confirmation-container > p {
    margin-top: 30px;
  }

  .tds-landing-request-access-confirmation-container button {
    width: 100%;
    margin-top: 26px;
  }

  .tds-landing-request-access .tds-landing-logo,
  .tds-landing-request-access-confirmation .tds-landing-logo {
    display: block;
    margin-top: 7px;
  }

  .tds-landing-request-access-confirmation .tds-landing-logo {
    align-self: flex-start;
  }

  .tds-landing-confirmation-close {
    position: absolute;
  }

  .tds-td-employee-form-four-column {
    width: auto;
  }

  .tds-td-employee-form-dropdown {
    width: auto;
    font-weight: bold;
  }
}
.PhoneInputInput {
  border: 0;
}

.PhoneInputInput:focus {
  outline: none;
}

.PhoneInputCountry {
  line-height: 0;
}

/* TD EMERALD Standards Overrides BEGIN */

/* TD EMERALD Standards Overrides BEGIN */
