.opm-error-container {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 100px;
  margin-top: 100px;
  margin-bottom: 100px;
}
