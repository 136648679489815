.tds-homepage-container {
  display: flex;
  margin-top: 3px;
  flex-wrap: wrap;
}

.landing-main-request-access-box {
  background-color: #f6faf0;
  /*  border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;*/
  font-family: "TD Graphik SemiLight";
  font-weight: bold;
  padding-left: 2%;
  //margin-right: 1em;
  padding-top: 0.7%;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  margin-top: 15px;
  height: max-content;
  flex-direction: column;
}

.landing-main-banner-title {
  min-width: 310px;
}

.tds-homepage-video-bar-button {
  margin-top: 10px;
}

.landing-request-access-btn {
  width: 150px;
  flex-shrink: 0;

  img {
    display: none;
    height: 8px;
    float: right;
    margin-top: 8px;
  }

  span.active > img {
    display: inline;
  }
}

.landing-main-content-top-container {
  display: flex;
  flex-direction: column;
}

.landing-main-content-filter-section {
  width: 16%;
  margin-top: 1.3em;
}

.landing-main-content-app-section {
  width: 84%;
  margin-top: 1em;
}

.landing-main-content-container {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 767px) {
  .landing-main-content-filter-section {
    display: none;
  }
  .landing-main-content-app-section {
    width: 100%;
  }
  .landing-main-request-access-box {
    margin-left: 2%;
    margin-right: 2%;
  }
}
@media only screen and (max-width: 1300px) {
  .landing-main-content-filter-section {
    margin-left: 1em;
    margin-right: 1em;
  }
}

@media only screen and (max-width: 1023px) {
  .landing-main-content-filter-section {
    display: none;
  }
  .landing-main-request-access-box {
    margin-left: 1%;
    margin-right: 1%;
  }
  .landing-main-content-app-section {
    width: 100%;
  }
}
