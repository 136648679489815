/* Desktop */

.tds-three-column-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 0px 20px 0px;
  margin: 5px 0px 20px 0px;
}

.tds-three-column-container.no-sidebar .tds-three-column-title-container {
  text-align: center;
}

.tds-lock-image {
  width: 16px !important;
  height: 11px !important;
  margin-bottom: 4px;
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-three-column-title-container.error-top-cards {
    min-height: 350px;
  }

  .tds-three-column-container {
    width: 100%;
    padding: 0px 20px 20px 20px;
  }
}

/* Tablet */

@media only screen and (max-width: 1023px) {
  .tds-homepage-container .tds-three-column-container {
    width: 100%;
    //padding: 0px 0px 20px 0px;
    padding: 0px 20px 20px 20px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .tds-homepage-container .tds-three-column-container {
    width: 100%;
    //padding: 0px 0px 20px 0px;
    padding: 0px 20px 20px 20px;
  }
}

/* MOBILE */

@media only screen and (max-width: 767px) {
  .tds-homepage-container .tds-three-column-container {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  // .tds-three-column-container {
  //   margin-bottom: 0px;
  //   padding-bottom: 0px;
  // }

  .tds-homepage-container .tds-three-column-container {
    width: 100%;
    padding: 0px 20px 20px 20px;
  }
}

@media only screen and (max-width: 375px) {
}

/* Desktop */
.tds-homepage-container .tds-three-column-container h2 {
  display: block;
  width: 100%;
  font-size: 22px;
  line-height: 1;
  margin-bottom: 0px;
}

.tds-three-column-title-container {
  width: 100%;
  padding: 0px;
  align-self: flex-start;
  margin-bottom: 30px;
}

.tds-three-column-single-container {
  //width: 313px;
  width: 350px;
  //new flex-basis: 30%;
  margin-right: 20px;
  margin-bottom: 20px;
  height: auto;
  -webkit-box-shadow: 0px 0px 0px #eee, -2px 2px 2px #eee, 2px 2px 2px #eee;
  box-shadow: 0px 0px 0px #eee, -2px 2px 2px #eee, 2px 2px 2px #eee;
}

.tds-three-column-single-container:nth-child(3n + 1) {
  margin-right: 0px;
}

.tds-three-column-single-container-content {
  padding: 4% 5% 3%;
}

.tds-three-column-single-container-content h3 {
  color: #038203;
  font-size: 16px;
}

.tds-three-column-single-container-date,
.tds-three-column-single-container-byline,
.tds-three-column-single-container-description,
.tds-three-column-single-container-read-full-article {
  font-size: 13px;
  display: block;
  padding: 2px 0px;
}

.tds-three-column-single-container-read-full-article {
  color: #038203;
}

.tds-three-column-single-container-description {
  height: 44px;
  overflow: hidden;
}

.tds-three-column-container.tablet-mobile {
  display: none;
}

.tds-tailored-for-you-image {
  height: 115px;
  //   width: 313px;
  width: 350px;
  object-fit: cover;
}

.tds-three-column-single-container-equities-info {
  font-size: 13px;
}
.tds-three-column-single-container-equities-info dl {
  margin: 0px;
  padding: 0px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.tds-three-column-single-container-equities-info dd {
  width: 40%;
  height: auto;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
}

.tds-three-column-single-container-equities-info dt {
  width: 60%;
  height: auto;
  font-weight: normal;
  margin: 0px;
  padding: 0px;
}
@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-three-column-single-container {
    width: 31%;
    margin-right: 2%;
    margin-bottom: 2%;
  }

  .tds-three-column-single-container img {
    width: 100%;
  }
}

/* Tablet */

@media only screen and (max-width: 1023px) {
  .tds-three-column-single-container {
    width: 49%;
    margin-right: 2%;
  }

  .tds-three-column-single-container:nth-child(odd) {
    margin-right: 0px;
  }

  .tds-three-column-single-container:nth-child(even) {
    margin-right: 2%;
  }

  .tds-three-column-single-container img {
    width: 100%;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
}

/* MOBILE */

@media only screen and (max-width: 767px) {
  .tds-homepage-container .tds-three-column-container h2 {
    font-size: 18px;
  }

  .tds-three-column-single-container {
    width: 100%;
  }

  .tds-three-column-single-container:nth-child(odd),
  .tds-three-column-single-container:nth-child(even) {
    margin-right: 0px;
  }

  .tds-three-column-single-container-content h3 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 375px) {
}

/* TD EMERALD Standards Overrides BEGIN */

/* TD EMERALD Standards Overrides BEGIN */

.category-item-container-card {
  display: grid;
  grid-auto-columns: minmax(100px, auto);
}

.category-item-description-box {
  min-height: 4.7em;
  max-height: 4.7em;
  line-height: 1.5em;
  word-wrap: break-word;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.category-item-container {
  position: relative;
  //cursor: pointer;
}

/*.category-item-hover-text {
  z-index: 1;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40%;
  text-align: center;
  font-size: 150%;
  font-weight: bold;
  color: white;
}*/

.no-title-category-card {
  margin-top: -30em;
}

/*.category-item-hover{
  padding: 0px;
  background-color: #007c1b;
  opacity: 0.9;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
  height: 100%;
}*/

.category-item-login-button {
  max-width: 90%;
  margin-left: 5%;
  margin-bottom: 5%;
}
