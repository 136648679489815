.tds-homepage-container {
  display: flex;
  margin-top: 3px;
  flex-wrap: wrap;
}

.tds-divider-space-10 {
  margin: 5px;
  width: 100%;
}

.tds-homepage-video-bar {
  width: 100%;
  height: 310px;

  background-size: cover;
  background-repeat: no-repeat;
}

.tds-homepage-video-content {
  width: 50%;
  margin-right: 0;
  margin-left: auto;
  background-image: linear-gradient(
                  to right,
                  rgba(243, 243, 248, 0),
                  #f3f3f8 30%
  );
  padding-left: 7%;
  height: 100%;

  display: flex;
  align-items: center;

  h1 {
    margin-bottom: 0px;
    font-size: 1.7rem;
  }

  section {
    max-width: 500px;
  }
  button {
    max-width: 180px;
  }
}

.one-portal-banner-title {
  min-width: 310px;
}

.landing-categories-container {
  display: flex;
  flex-direction: row;
}

.container {
  display: grid;
  grid-template-columns: 1% 98% 1%;
}

.container2 {
  display: grid;
  grid-template-columns: 14% 86%;
}

.title-item {
  grid-column: 1 / span 3;
}
.main-tab-navigator-container {
  display: flex;
  margin-top: 1em;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.landing-categories-filter {
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;
  width: 16%;
}

.landing-main-apps-container {
  width: 84%;
  margin-top: 1em;
}

.landing-request-access-box {
  margin-top: 2em;
}

.landing-apps-container {
  width: 100%;
}

.main-card-hover {
  padding: 20px;
  background-color: #007c1b;
  opacity: 0.9;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: none;
  height: 100%;
}

.landing-one-column-tab-navigator {
  width: 16%;
}
.landing-three-column-tab-navigator {
  width: 84%;
}

.landing-filter-and-apps-container {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 767px) {
  .landing-main-apps-container {
    width: 100%;
  }
  .landing-one-column-tab-navigator {
    display: none;
  }

  .filter-category-section {
    display: none;
  }
  .landing-three-column-tab-navigator {
    width: 100%;
  }
  .landing-categories-filter {
    display: none;
  }
  .landing-categories-container {
    margin-left: 1em;
    margin-right: 1em;
  }
  .tds-homepage-video-content {
    width: 85%;
    padding-left: 0%;
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-homepage-video-content {
    padding-left: 4%;
  }
}

@media only screen and (max-width: 1300px) {
  .landing-categories-container {
    margin-left: 1em;
    margin-right: 1em;
  }
}

/* Tablet */

@media only screen and (max-width: 1023px) {
  .tds-sn-home-container {
    margin-top: 45px;
  }

  .tds-homepage-video-content {
    padding-left: 4%;
  }

  .landing-categories-filter {
    display: none;
  }

  .landing-main-apps-container {
    width: 100%;
  }

  .landing-one-column-tab-navigator {
    display: none;
  }

  .landing-three-column-tab-navigator {
    width: 100%;
  }
  .main-tab-navigator-container{
    width: 50%
  }
}
