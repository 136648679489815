/* 
    DO NOT MODIFY DIRECTLY IN PROJECT gsbm-continuous-testing

    ALWAYS COPY FROM tds-one-portal-static master branch

*/

/* Desktop */

.tds-footer-divider {
  background-color: #1a5336;
  height: 5px;
  clear: both;
  margin-top: 30px;
}

.tds-footer-top {
  position: absolute;
  right: 4%;
  margin-top: -5%;
  border: 1px solid #008a00;
  border-radius: 25px;
  padding: 8px;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 0 #b5b5b5;
  -moz-box-shadow: 0 0 10px 0 #b5b5b5;
  box-shadow: 0 0 10px 0 #b5b5b5;
  color: #038203;
  cursor: pointer;
}

.tds-footer-top.fixed {
  position: fixed;
  right: 4%;
  top: 98%;
  border: 1px solid #008a00;
  border-radius: 25px;
  padding: 8px;
  background-color: white;
  -webkit-box-shadow: 0 0 10px 0 #b5b5b5;
  -moz-box-shadow: 0 0 10px 0 #b5b5b5;
  box-shadow: 0 0 10px 0 #b5b5b5;
  color: #038203;
  cursor: pointer;
}

.tds-footer-top .td-icon-upCaret {
  font-size: 18px;
  float: left;
  padding: 3px;
  padding: 3px;
  margin-left: 5px;
  margin-top: 2px;
}

.tds-footer-top-text {
  float: right;
  padding-right: 5px;
  font-family: "TD Graphik SemiBold", Helvetica-, droid sans, sans-serif;
  font-size: 14px;
}

.tds-footer-contact-us {
  text-align: center;
  font-size: 20px;
  margin: 30px 0px;
  color: #038203;
  cursor: pointer;
}

.tds-footer-contact-us span {
  padding-left: 5px;
}

.tds-footer-contact-us-text {
  font-weight: bold;
}

.tds-footer-contact-us .td-icon-rightCaret {
  font-size: 20px;
  font-weight: bold;
}

.tds-footer-links {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.tds-footer-links ul {
  list-style: none;
  display: block;
  margin: 30px auto;
  //width: 90%;
  padding: 0px;
}

.tds-footer-links ul li {
  width: 20%;
  display: block;
  float: left;
  text-align: center;
  color: #616161;
}

.tds-footer-link {
  color: #616161;
  cursor: pointer;
  font-size: 13px;
}

.tds-footer-link:hover {
  color: #616161;
  text-decoration: underline;
}

.tds-footer-logos {
  width: 50%;
  margin: 30px auto;
}

.tds-footer-logos img {
  padding: 20px 10%;
}

.tds-footer-note {
  font-size: 11px;
  display: block;
  margin: 30px auto;
  width: 60%;
  text-align: center;
}

.tds-footer-note a {
  font-weight: bold;
}

.tds-footer-modal {
  max-width: 960px;
  max-height: 85%;
}

@media only screen and (max-width: 1300px) and (min-width: 1024px) {
  .tds-footer-logos {
    width: 80%;
    margin: 30px auto;
    text-align: center;
  }

  .tds-footer-logos img {
    padding: 20px 5%;
  }
}

/* Tablet */

@media only screen and (max-width: 1023px) {
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .tds-footer-links {
    width: 100%;
  }

  .tds-footer-links ul {
    width: 80%;
  }

  .tds-footer-logos {
    width: 100%;
    margin: 30px auto;
    text-align: center;
  }

  .tds-footer-top-text {
    padding-right: 20px;
  }

  .tds-footer-top,
  .tds-footer-top.fixed {
    right: 0;
    margin-right: -20px;
    top: 93%;
  }
}

/* MOBILE */

@media only screen and (max-width: 767px) {
  .tds-footer-links {
    width: 100%;
  }

  .tds-footer-links ul {
    width: 100%;
  }

  .tds-footer-links ul li {
    width: 100%;
    margin: 10px 0px;
  }

  .tds-footer-contact-us .td-icon-rightCaret {
    display: none;
  }

  .tds-footer-logos {
    width: 100%;
    margin: 30px auto;
    text-align: center;
  }

  .tds-footer-top-text {
    padding-right: 20px;
  }

  .tds-footer-top,
  .tds-footer-top.fixed {
    right: 0;
    margin-right: -20px;
    top: 93%;
  }
}

@media only screen and (max-width: 375px) {
}

/* TD EMERALD Standards Overrides BEGIN */

/* TD EMERALD Standards Overrides BEGIN */
