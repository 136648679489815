.tds-error {
  color: #1c1c1c;
  font-family: "TD Graphik Semilight";
  font-size: 15px;
  background-color: #f3f3f8;
  padding: 30px 20px;
  width: 100%;
  text-align: center;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 75px;
}
