/* 
    DO NOT MODIFY DIRECTLY IN PROJECT gsbm-continuous-testing

    ALWAYS COPY FROM tds-one-portal-static master branch
*/

/* Desktop */
.tds-fourofour-page .tds-fourofour-page-container {
  background-image: url("../assets/images/404_DT.jpg");
  height: 520px;
  margin-bottom: -30px;
  background-repeat: no-repeat;
  background-position: 50% 1px;
  background-size: cover;
}

.tds-fourofour-page.unauthenticated .tds-fourofour-page-container {
  height: 620px;
  margin-bottom: -40px;
}

.tds-fourofour-page-row {
  position: relative;
  top: 30%;
  width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 32px;
  padding-right: 32px;
}

.tds-fourofour-page-column-description p {
  font-size: 36px;
  line-height: 2.8rem;
  font-family: "TD Graphik Light", sans-serif;
  width: 50%;
}

/* Tablet */

@media only screen and (max-width: 1023px) {
  .tds-fourofour-page .tds-fourofour-page-container {
    background-image: url("../assets/images/404_TL.jpg");
  }

  .tds-fourofour-page-row {
    width: 100%;
    top: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .tds-fourofour-page-column {
    text-align: center;
  }

  .tds-fourofour-page-column-description p {
    width: 100%;
    font-size: 28px;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
  .tds-fourofour-page .tds-fourofour-page-container {
    background-image: url("../assets/images/404_TP.jpg");
  }
}

@media only screen and (max-width: 767px) {
  .tds-fourofour-page .tds-fourofour-page-container {
    background-image: url("../assets/images/404_Mob.jpg");
  }
}

/* MOBILE */

/* TD EMERALD Standards Overrides BEGIN */

/* TD EMERALD Standards Overrides BEGIN */
