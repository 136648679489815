.landing-request-access-box-title {
  padding: 0;
  margin-top: 10px;
  font-size: 1.2em;
  color: #038203;
}

.landing-request-access-box-button-container{
  margin-top: 20px;
  margin-bottom: 20px;
}

.landing-request-access-btn {
  width: 150px
}